<script setup lang="ts">
import type { HomeBanners, BannerPresetPackage } from "@/types";

const { banners } = useHomePage();
const isGuest = useIsGuest();
</script>

<template>
	<div v-if="banners?.length && banners.length > 0">
		<OBannerUser v-if="!isGuest" :banners="banners as HomeBanners & BannerPresetPackage[]" />
		<OBannerGuest v-else :banner="banners?.[0] as HomeBanners[number]" />
	</div>
</template>
